import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../components/navbar.js";
import Footer from "../components/footer.js";
import "../css/index.css";
import favicon from '../assets/favicon.ico';

const ImpressumPage = () => {
  return (
    <div>
      <Helmet>
        <title>Pneuhaus Rogenmoser</title>
        <meta charset="utf-8" />
        <meta name="description" content="Willkommen bei Pneuhaus Rogenmoser in Auw. Ihr Reifenhändler in der Region Freiamt." />
        <meta name="keywords" content="Pneu,Pneuservice,Pneuverkauf,Pneuhandel,Reifenservice,Reifenhandel,Auw,Muri,Merenschwand,Sins,Freiamt,Aargau,Reifen," />
        <meta name="robots" content="noindex" />
        <meta name="author" content="Melanie Rogenmoser"/>
        <meta name="copyright" content="Melanie Rogenmoser"/>
        <meta name="date" content="2022-08-09"/>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <Navbar />
      <div className="containerimpressum">
      <div className="text">
          <h2>Impressum</h2>
          <h5>Pneuhaus Rogenmoser GmbH</h5>
          <h5>Industriestrasse 19</h5>
          <h5>5644 Auw</h5>
          <h5>Tel. 079 445 14 27</h5>
          <h5>E-Mail: pneu.rogenmoser@bluewin.com</h5>
          <br></br>
          <h5>Vertretungsberechtigte Person:</h5>
          <h5>Heinz Rogenmoser</h5>
          <br></br>
          <h5>HR-Nr: CH-400.4.019.065-5</h5>
          <h5>Mwst-Nr: CHE-104.009.259</h5>
      </div>
      </div>
      <Footer/>
    </div>
  );
};

export default ImpressumPage;